<template>
  <CRow>
    <CCol>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
        <CCard>
          <CCardHeader><font-awesome-icon icon="pencil-ruler" fixed-width /> <strong>報價內容</strong> </CCardHeader>
          <CCardBody>
            <label>類型</label>
            <b-form-tags class="mb-3" placeholder="請輸入" v-model="types" add-button-text="新增"></b-form-tags>
            <label>施工地點狀況選項</label>
            <b-form-tags class="mb-3" placeholder="請輸入" v-model="locationStatuses" add-button-text="新增"></b-form-tags>
            <label>縣市</label>
            <b-form-tags class="mb-3" placeholder="請輸入" v-model="counties" add-button-text="新增"></b-form-tags>
            <label>區域</label>
            <CRow>
              <CCol> <CSelect :options="counties" placeholder="請選擇" @update:value="countyUpdate" /></CCol>
              <CCol v-if="county">
                <b-form-tags class="mb-3" placeholder="請輸入" v-model="districts" add-button-text="新增" @input="districtUpdate"></b-form-tags>
              </CCol>
              <CCol v-else>
                <CInput disabled />
              </CCol>
            </CRow>
            <label>服務項目</label>
            <b-form-tags class="mb-3" placeholder="請輸入" v-model="services" add-button-text="新增" @input="serviceUpdate"></b-form-tags>
            <hr class="mt-4 mb-4" />
            <CTabs variant="pills" :vertical="{ navs: 'col-md-2', content: 'col-md-10' }">
              <CTab v-for="item in servicelist" :key="item.content">
                <template slot="title"> {{ item.content }} </template>
                <CRow class="mb-3" v-for="(service, index) in item.services" :key="index">
                  <CCol>
                    <CForm inline>
                      <CInput class="d-none" />
                      <CInput class="mr-3" v-model="service.title" />
                      <CSelect
                        class="min-w-8"
                        :options="[
                          { value: 0, label: '單選' },
                          { value: 1, label: '複選' },
                        ]"
                        :value.sync="service.type"
                      />
                      <CButton type="button" tabindex="-1" color="link" @click="removeServiceItem(item.content, index)">移除</CButton>
                    </CForm>
                    <b-form-tags class="mb-3 mt-2" placeholder="請輸入" v-model="service.options" add-button-text="新增"></b-form-tags>
                  </CCol>
                </CRow>
                <CButton type="button" tabindex="-1" color="link" @click="addServiceItem(item.content)">增加選項</CButton>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton type="button" color="primary" class="mr-1" @click="update()">確認更新</CButton>
          </CCardFooter>
        </CCard>
      </b-overlay>
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: "",
  data: () => ({
    loading: true,
    types: [],
    locationStatuses: [],
    counties: [],
    districts: [],
    districtlist: [],
    county: "",
    services: [],
    servicelist: [],
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$http.get("/quote/content").then((response) => {
        if (response.data.success) {
          this.types = response.data.result.types;
          this.locationStatuses = response.data.result.locationStatuses;
          this.counties = response.data.result.counties.map((p) => p.content);
          this.districtlist = response.data.result.counties;

          this.servicelist = response.data.result.services;
          this.services = this.servicelist.map((p) => p.content);
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    update() {
      this.loading = true;
      this.districtlist = this.districtlist.filter((p) => this.counties.includes(p.content));
      this.$http.post("/quote/content", { types: this.types, locationStatuses: this.locationStatuses, counties: this.districtlist, services: this.servicelist }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "更新成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    countyUpdate(val) {
      let county = this.districtlist.find((p) => p.content === val);
      if (!county) {
        county = { content: val, districts: [] };
        this.districtlist.push(county);
      }
      this.districts = this.districtlist.find((p) => p.content === val).districts;
      this.county = county.content;
    },
    districtUpdate(val) {
      this.districtlist.find((p) => p.content === this.county).districts = val;
    },
    serviceUpdate(val) {
      this.servicelist = this.servicelist.filter((p) => val.includes(p.content));

      val.forEach((item) => {
        if (!this.servicelist.some((p) => p.content === item)) {
          this.servicelist.push({
            content: item,
            services: [],
          });
        }
      });
    },
    addServiceItem(val) {
      this.servicelist
        .find((p) => p.content == val)
        .services.push({
          title: "",
          type: 0,
          options: [],
        });
    },
    removeServiceItem(content, index) {
      this.servicelist.find((p) => p.content == content).services.splice(index, 1);
    },
  },
};
</script>
